// Libraries
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/home";
import Login from "./pages/login";
import Logout from "./pages/logout";

// Utils
import checkAuth from "./utils/checkAuth";
import Register from "./pages/register";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import Order from "./pages/order";
import Orders from "./pages/orders";
import Rates from "./pages/rates";
import Users from "./pages/users";
import User from "./pages/user";
import Converter from "./components/Converter";

const AuthRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAuth() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const App = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <AuthRoute exact path="/" component={Home} />
        <AuthRoute exact path="/orders" component={Orders} />
        <AuthRoute path="/orders/:id" component={Order} />
        <AuthRoute exact path="/users" component={Users} />
        <AuthRoute path="/users/:id" component={User} />
        <AuthRoute path="/rates" component={Rates} />
        <Route path="/login" component={Login} />
        <Route path="/converter" component={Converter} />
        <Route path="/register" component={Register} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/logout" component={Logout} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
