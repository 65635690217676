import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, gql, useMutation } from "@apollo/client";

import Rates from "./Rates";

const RATES_QUERY = gql`
  query RateQuery(
    $from_currency: String!
    $to_currency: String!
    $from_amount: Float
  ) {
    rate(
      from_currency: $from_currency
      to_currency: $to_currency
      from_amount: $from_amount
    ) {
      rate
    }
    isOnline: setting(name: "change_online") {
      value
    }
  }
`;

const ORDER_CREATE = gql`
  mutation OrderCreate($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      id
    }
  }
`;

type ConverterProps = {
  me?: any;
  refetch?: any;
};

const Converter: React.FC<ConverterProps> = ({ me, refetch }) => {
  const { t } = useTranslation();

  const [getRate, { error, data }] = useLazyQuery(RATES_QUERY, {
    fetchPolicy: "no-cache",
  });

  const [orderCreate] = useMutation(ORDER_CREATE, {
    onError: (error) => {
      $("#create_order").modal("hide");

      switch (error.message) {
        case "limit_reached":
          $("#error_alert").modal("show");
          break;
      }
    },
    onCompleted: () => {
      refetch();
    },
  });

  const initialState = {
    from_amount: "1000",
    from_currency: "CHF",
    to_currency: "EUR",
    iban: "",
    terms_check: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    getRate({
      variables: {
        from_currency: state.from_currency,
        to_currency: state.to_currency,
        from_amount: parseAmount(state.from_amount),
      },
    });
  }, [state.from_amount, state.from_currency, state.to_currency, getRate]);

  const resetState = () => {
    setState(initialState);
  };

  const parseAmount = (amount: string) => {
    const parsed = parseFloat(amount);
    if (isNaN(parsed)) {
      return 0.0;
    }
    return parsed;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    orderCreate({
      variables: {
        input: {
          from_currency: state.from_currency,
          to_currency: state.to_currency,
          iban: state.iban,
          from_amount: parseFloat(state.from_amount),
        },
      },
    });

    $("#create_order").modal("hide");
    resetState();
  };

  if (error) {
    return <div className="alert alert-danger">Error</div>;
  }

  if (data && data.isOnline.value === "false") {
    return <div className="alert alert-danger">{t("home.change_offline")}</div>;
  }

  return (
    <div className="mb-3 card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-8 col-lg-9">
            {me && <h3>{t("home.change_currency_title")}</h3>}
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-4">
                <label htmlFor="from_value">{t("home.value_name")}</label>
                <input
                  type="number"
                  className="form-control"
                  id="from_value"
                  value={state.from_amount}
                  step="any"
                  required
                  onChange={(e) => {
                    setState({
                      ...state,
                      from_amount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-8">
                <label
                  className="d-none d-sm-inline-block"
                  htmlFor="from_currency"
                ></label>
                <div className="input-group">
                  <select
                    id="from_currency"
                    className="form-select"
                    value={state.from_currency}
                    onChange={(e) => {
                      setState({
                        ...state,
                        from_currency: e.target.value,
                        to_currency: state.from_currency,
                      });
                    }}
                  >
                    <option value="CHF">CHF</option>
                    <option value="EUR">EUR</option>
                  </select>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setState({
                        ...state,
                        from_currency: state.to_currency,
                        to_currency: state.from_currency,
                      });
                    }}
                  >
                    <i className="bi bi-arrow-repeat"></i>
                  </button>
                  <select
                    id="to_currency"
                    className="form-select"
                    value={state.to_currency}
                    onChange={(e) => {
                      setState({
                        ...state,
                        to_currency: e.target.value,
                        from_currency: state.to_currency,
                      });
                    }}
                  >
                    <option value="EUR">EUR</option>
                    <option value="CHF">CHF</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-12 d-flex flex-column justify-content-center">
                <h5 className="mb-0 text-muted">{`${parseAmount(
                  state.from_amount
                )} ${state.from_currency} =`}</h5>
                <h3 className="mb-0">{`${
                  data ? parseAmount(data.rate.rate).toFixed(2) : 0
                } ${state.to_currency}`}</h3>
              </div>
              {me && (
                <div className="col-md-12 col-xl-8">
                  <label htmlFor="bank_account_selector">
                    {t("home.bank_account_destination")}
                  </label>
                  <div className="input-group">
                    <select
                      id="bank_account_selector"
                      className="form-select"
                      value={state.iban}
                      onChange={(e) => {
                        setState({ ...state, iban: e.target.value });
                      }}
                      required
                    >
                      <option value="" disabled>
                        {t("home.select_bank_account")}
                      </option>
                      {me.bank_accounts
                        .filter(({ active }: any) => active)
                        .filter(
                          ({ currency_id }: any) =>
                            currency_id === state.to_currency
                        )
                        .map(({ iban, currency_id, bank_name }: any) => (
                          <option key={iban} value={iban}>
                            {`${iban} (${bank_name}) (${currency_id})`}
                          </option>
                        ))}
                    </select>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#create_order"
                      disabled={state.iban === ""}
                    >
                      {t("home.submit_order")}
                    </button>
                  </div>
                </div>
              )}
              <div className="modal fade" id="error_alert">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      Limite settimanale ordini superato
                      <br />
                      <br />
                      Per rimuovere il limite contattare Cambio Parini al:{" "}
                      <br />
                      <a href={`tel:${t("company.phone")}`}>
                        {t("company.phone")}
                      </a>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        {t("global.close")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="create_order"
                tabIndex={-1}
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{t("home.submit_order")}</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>{t("home.value_name")}</td>
                            <td>
                              <b>{`${state.from_currency} ${parseAmount(
                                state.from_amount
                              ).toFixed(2)}`}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>{t("home.value_receiving_name")}</td>
                            <td>
                              <b>{`${state.to_currency} ${
                                data
                                  ? parseAmount(data.rate.rate).toFixed(2)
                                  : 0.0
                              }`}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>{t("home.bank_account_destination")}</td>
                            <td>{state.iban}</td>
                          </tr>
                        </tbody>
                      </table>
                      {t("home.order_confirm", {
                        returnObjects: true,
                        joinArrays: ";",
                      })
                        .split(";")
                        .map((text, index) => (
                          <p key={index}>{text}</p>
                        ))}

                      <div className="form-check">
                        <input
                          id="order_terms_confirm"
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={state.terms_check}
                          onChange={(e) => {
                            setState({
                              ...state,
                              terms_check: e.target.checked,
                            });
                          }}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="order_terms_confirm"
                        >
                          {t("home.terms_confirm.before")}
                          <a
                            href={t("company.terms_link")}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("home.terms_confirm.link")}
                          </a>
                          {t("home.terms_confirm.after")}
                        </label>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setState({ ...state, terms_check: false });
                        }}
                      >
                        {t("global.cancel")}
                      </button>
                      <button type="submit" className="btn btn-primary">
                        {t("global.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Rates
            from_currency={state.from_currency}
            to_currency={state.to_currency}
          />
        </div>
      </div>
    </div>
  );
};

export default Converter;
