import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import isIBAN from "validator/lib/isIBAN";

const BANK_ACCOUNT_CREATE = gql`
  mutation BankAccountCreate($input: BankAccountCreateInput!) {
    bankAccountCreate(input: $input) {
      iban
    }
  }
`;

const NewBankAccount = ({ refetch }: any) => {
  const { t } = useTranslation();
  const ibanRef = useRef<HTMLInputElement>(null);

  const [bankAccountCreate] = useMutation(BANK_ACCOUNT_CREATE);

  const initialState = {
    iban: "",
    currency_id: "",
    beneficiary: "",
    bank_name: "",
    bic_code: "",
  };

  const [form, setForm] = useState(initialState);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await bankAccountCreate({
        variables: { input: form },
      });

      $("#iban").removeClass("is-invalid");

      setForm(initialState);
      refetch();
      $("#add_bank_account").modal("hide");
    } catch (err) {
      $("#bankaccount_error").text(`${t("BankAccount.iban_not_available")}`);
      $("#iban").addClass("is-invalid");
    }
  };

  const checkFields = () => {
    if (
      isIBAN(form.iban) &&
      form.bank_name &&
      form.beneficiary &&
      form.currency_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="modal fade"
      id="add_bank_account"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">{t("home.add_bank_account")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="iban" className="form-label">
                  {t("BankAccount.iban")}
                </label>
                <input
                  ref={ibanRef}
                  id="iban"
                  type="text"
                  className="form-control"
                  value={form.iban}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      iban: e.target.value.replace(/\s/g, ""),
                    });
                  }}
                  onBlur={(e) => {
                    if (!isIBAN(form.iban)) {
                      e.target.classList.add("is-invalid");
                      $("#bankaccount_error").text(
                        `${t("BankAccount.iban_invalid")}`
                      );
                    } else {
                      e.target.classList.remove("is-invalid");
                    }
                  }}
                  required
                />
                <div id="bankaccount_error" className="invalid-feedback">
                  {t("BankAccount.iban_invalid")}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="currency_id" className="form-label">
                  {t("BankAccount.currency_id")}
                </label>
                <select
                  id="currency_id"
                  className="form-select"
                  value={form.currency_id}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    {t("BankAccount.currency_id_placeholder")}
                  </option>
                  <option value="CHF">CHF</option>
                  <option value="EUR">EUR</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="beneficiary" className="form-label">
                  {t("BankAccount.beneficiary")}
                </label>
                <input
                  id="beneficiary"
                  type="text"
                  className="form-control"
                  value={form.beneficiary}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="bank_name" className="form-label">
                  {t("BankAccount.bank_name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bank_name"
                  value={form.bank_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="bic_code" className="form-label">
                  {t("BankAccount.bic_code")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bic_code"
                  value={form.bic_code}
                  onChange={handleChange}
                />
              </div>
              <div className="form-check">
                <input
                  id="checkbox_beneficiary_required"
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="checkbox_beneficiary_required"
                >
                  {t("BankAccount.beneficiary_confirm")}
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("global.cancel")}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!checkFields()}
              >
                {t("global.add")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewBankAccount;
