import { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../utils/rate";
import { Link } from "react-router-dom";

type OrderProps = {
  orders: any[];
  role?: "user" | "admin";
};

const Orders: React.FC<OrderProps> = ({ orders, role = "user" }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let translationUrl =
      "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Italian.json";

    switch (i18n.language) {
      case "en":
        translationUrl =
          "https://cdn.datatables.net/plug-ins/1.10.22/i18n/English.json";
    }
    $("#orders").DataTable({
      order: [[0, "desc"]],
      language: {
        url: translationUrl,
      },
      destroy: true,
    });
  }, [i18n.language]);
  return (
    <div className="table-responsive p-1">
      <table id="orders" className="table table-sm align-middle">
        <thead>
          <tr>
            {role === "admin" && <th>{t("home.orders.id")}</th>}
            <th>{t("home.orders.date")}</th>
            <th>{t("home.orders.from")}</th>
            <th>{t("home.orders.value")}</th>
            <th>{t("home.orders.to")}</th>
            <th>{t("home.orders.value")}</th>
            <th>{t("home.orders.status")}</th>
            <th>{t("home.orders.beneficiary")}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order: any) => (
            <tr key={order.id}>
              {role === "admin" && (
                <td>
                  <Link to={`/orders/${order.id}`}>{order.id}</Link>
                </td>
              )}

              <td data-sort={order.date}>
                {moment.unix(order.date / 1000).format(t("global.date_format"))}
              </td>
              <td>{order.from_currency}</td>
              <td>
                <b>{formatCurrency(parseFloat(order.from_amount))}</b>
              </td>
              <td>{order.to_currency}</td>
              <td>
                <b>{formatCurrency(parseFloat(order.to_amount))}</b>
              </td>
              <td>
                {t(`Order.order_status.${order.status.name}`)}
                <br />
                {t(`Order.order_status_reason.${order.status_reason.name}`)}
              </td>
              <td>
                {order.bank_account.beneficiary}
                <br />
                {order.bank_account.iban}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
