import { useTranslation } from "react-i18next";
import EditBankAccount from "./modals/EditBankAccount";

import NewBankAccount from "./modals/NewBankAccount";

type BankAccountsProps = {
  bank_accounts: any[];
  refetch: any;
};

const BankAccounts: React.FC<BankAccountsProps> = ({
  bank_accounts,
  refetch,
}) => {
  const { t } = useTranslation();

  return (
    <div className="card mt-3">
      <div className="card-body">
        <h5 className="mb-0">{t("home.bank_accounts")}</h5>
      </div>
      <div className="list-group list-group-flush">
        {bank_accounts.map((bank_account: any) => (
          <div className="list-group-item" key={bank_account.iban}>
            <a
              href={`#edit_bank_account_${bank_account.iban}`}
              role="button"
              data-bs-toggle="modal"
              className="text-decoration-none text-dark d-flex justify-content-between align-items-center"
              style={{ fontSize: "0.9rem" }}
            >
              {bank_account.iban}
              <span className="badge bg-primary rounded-pill">
                <i className="bi bi-pencil-square"></i>
              </span>
            </a>
            <EditBankAccount bank_account={bank_account} refetch={refetch} />
          </div>
        ))}
      </div>
      <div className="card-body">
        <div className="d-grid gap-2">
          <button
            className="btn btn-success"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#add_bank_account"
          >
            {t("home.add_bank_account")}
          </button>
        </div>
      </div>
      <NewBankAccount refetch={refetch} />
    </div>
  );
};

export default BankAccounts;
