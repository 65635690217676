import moment from "moment";
import { useTranslation } from "react-i18next";

import { useQuery, gql } from "@apollo/client";

const CURRENCIES_QUERY = gql`
  query CurrenciesQuery {
    CHF_EUR: rate(from_currency: "CHF", to_currency: "EUR", from_amount: 1) {
      rate
      date
    }

    EUR_CHF: rate(from_currency: "EUR", to_currency: "CHF", from_amount: 1) {
      rate
      date
    }
  }
`;

export interface RatesProps {
  from_currency: string;
  to_currency: string;
}

const Rates: React.FC<RatesProps> = ({ from_currency, to_currency }) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(CURRENCIES_QUERY);

  if (error)
    return <div className="alert alert-danger">Error retrieving rates</div>;

  if (loading) {
    return <div className="spinner"></div>;
  }

  if (!data) {
    return <div className="spinner"></div>;
  }

  const CHF_EUR =
    from_currency === "CHF"
      ? data.CHF_EUR.rate
      : 1000 / data.EUR_CHF.rate / 1000;
  const EUR_CHF =
    from_currency === "EUR"
      ? data.EUR_CHF.rate
      : 1000 / data.CHF_EUR.rate / 1000;

  return (
    <div className="mt-3 col-md-4 col-lg-3 mt-md-0">
      <h3>{t("home.rates")}</h3>
      <table className="table mb-1">
        <tbody>
          <tr>
            <td>
              <b>1</b> CHF
            </td>
            <td>
              <b>{CHF_EUR.toFixed(6)}</b> EUR
            </td>
          </tr>
          <tr>
            <td>
              <b>1</b> EUR
            </td>
            <td>
              <b>{EUR_CHF.toFixed(6)}</b> CHF
            </td>
          </tr>
        </tbody>
      </table>
      <p className="text-muted" style={{ fontSize: "0.85rem" }}>
        {moment
          .unix(data.CHF_EUR.date / 1000)
          .format(t("global.datetime_format"))}
      </p>
    </div>
  );
};

export default Rates;
