import React, { useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { Redirect } from "react-router";
import { useTranslation } from "react-i18next";

import checkAuth from "../utils/checkAuth";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        role
      }
    }
  }
`;

const Login = () => {
  const { t } = useTranslation();

  const [login, { data, error, loading }] = useMutation(LOGIN_MUTATION);

  const [form, setForm] = useState({ email: "", password: "" });

  if (checkAuth()) {
    return <Redirect to="/" />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    login({ variables: { input: form } });
  };

  if (error) {
    localStorage.removeItem("token");
    return (
      <div className="alert alert-danger container mt-3">
        Error connecting to database
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.login.jwt) {
    localStorage.setItem("token", data.login.jwt);
    localStorage.setItem("role", data.login.user.role);

    return <Redirect to="/" />;
  }

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: "70vh" }}
    >
      <div className="card shadow-sm w-100" style={{ maxWidth: "350px" }}>
        <div className="card-body">
          {data && data.login.jwt === null && (
            <div className="alert alert-danger">
              {t("User.password_invalid")}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <h3 className="text-center">{t("login.title")}</h3>
            <div className="text-center mb-3">
              {t("login.not_registered.before")}{" "}
              <Link to="/register">{t("login.not_registered.link")}</Link>
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                {t("User.email")}
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                {t("User.password")}
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={form.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-check mb-3">
              <input
                id="terms_confirm"
                className="form-check-input"
                type="checkbox"
                value=""
                required
              />
              <label className="form-check-label" htmlFor="terms_confirm">
                {t("home.terms_confirm.before")}
                <a
                  href={t("company.terms_link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("home.terms_confirm.link")}
                </a>
              </label>
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-block">
                {t("login.submit_button")}
              </button>
            </div>
          </form>
          <div className="text-center mt-3">
            <Link to="/forgotpassword">{t("login.forgotpassword.link")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
