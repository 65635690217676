import React, { useState } from "react";

import { useMutation, gql } from "@apollo/client";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import checkAuth from "../utils/checkAuth";

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`;

const ResetPassword = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = new URLSearchParams(useLocation().search);

  const token = location.get("token");

  const [resetPassword, { data, error }] = useMutation(RESET_PASSWORD_MUTATION);

  const initialState = { reset_token: token, new_password: "" };
  const initialConfirmState = { confirm_new_password: "" };

  const [form, setForm] = useState(initialState);
  const [confirm, setConfirm] = useState(initialConfirmState);

  if (checkAuth()) {
    return <Redirect to="/" />;
  }

  if (!token) {
    return <Redirect to="/forgotpassword" />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm({ ...confirm, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (form.new_password !== confirm.confirm_new_password) {
      return;
    }

    resetPassword({ variables: { input: form } });
  };

  const redirectLogin = (seconds: number = 5) => {
    setTimeout(() => {
      history.push("/login");
    }, seconds * 1000);
  };

  if (error) {
    return (
      <div className="alert alert-danger container mt-3">
        Error connecting to database
      </div>
    );
  }

  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: "70vh" }}
    >
      <div className="card shadow-sm w-100" style={{ maxWidth: "350px" }}>
        <div className="card-body">
          {data &&
            (data.resetPassword.success ? (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {t("resetpassword.success")}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
                {redirectLogin(5)}
              </div>
            ) : (
              <div className="alert alert-danger alert-dismissible fade show">
                {t("resetpassword.error")}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            ))}
          <form onSubmit={handleSubmit}>
            <h4 className="text-center mb-3">{t("resetpassword.title")}</h4>
            <div className="mb-3">
              <label htmlFor="new_password" className="form-label">
                {t("User.password_new")}
              </label>
              <input
                type="password"
                className="form-control"
                id="new_password"
                value={form.new_password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirm_new_password" className="form-label">
                {t("User.password_confirm_new")}
              </label>
              <input
                type="password"
                className="form-control"
                id="confirm_new_password"
                value={confirm.confirm_new_password}
                onChange={handleConfirmChange}
                onBlur={(e) => {
                  if (form.new_password !== e.target.value) {
                    e.target.classList.add("is-invalid");
                  } else {
                    e.target.classList.remove("is-invalid");
                  }
                }}
                required
              />
              <div className="invalid-feedback">
                {t("User.password_confirm_invalid")}
              </div>
            </div>

            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-block">
                {t("resetpassword.submit_button")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
