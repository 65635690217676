import { Redirect } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

const Logout = () => {
  const client = useApolloClient();

  client.resetStore();

  localStorage.removeItem("token");
  localStorage.removeItem("role");

  return <Redirect to="/login" />;
};

export default Logout;
