import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type UserProps = {
  users: any[];
};

const Users: React.FC<UserProps> = ({ users }) => {
  const { t } = useTranslation();

  useEffect(() => {
    let translationUrl =
      "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Italian.json";

    $("#users").DataTable({
      order: [[3, "desc"]],
      language: {
        url: translationUrl,
      },
      destroy: true,
    });
  }, []);

  return (
    <div className="table-responsive p-1">
      <table id="users" className="table align-middle">
        <thead>
          <tr>
            <th>{t("User.type")}</th>
            <th>{t("User.firstname")}</th>
            <th>{t("User.email")}</th>
            <th>{t("User.phone")}</th>
            <th>{t("User.created_at")}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: any) => (
            <tr key={user.id}>
              <td style={{ fontSize: 14 }}>{t(`home.type.${user.type}`)}</td>
              <td>
                <Link to={`/users/${user.id}`}>
                  {user.type === "personal" && (
                    <b>
                      {user.firstname} {user.lastname}
                    </b>
                  )}
                  {user.type === "company" && <b>{user.company_name}</b>}
                </Link>
                <span style={{ fontSize: 14 }}>
                  {(user.street_name || user.street_number) && (
                    <span>
                      <br />
                      {user.street_name || ""} {user.street_number || ""}
                    </span>
                  )}
                  {(user.zip_code || user.city) && (
                    <span>
                      <br />
                      {user.zip_code || ""} {user.city || ""}
                    </span>
                  )}
                </span>
              </td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td data-sort={user.created_at}>
                {moment
                  .unix(user.created_at / 1000)
                  .format(t("global.datetime_format"))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
